import { Value } from "@stringtale/react";
import { Routes } from "@blitzjs/next"
import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { AspectRatio } from "@radix-ui/react-aspect-ratio"
import useLinks from "app/core/hooks/useLinks"
import KidsLayout from "app/kids/components/KidsLayout"
import getKidPage from "app/kids/queries/getKidPage"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"
import { GREEN_COLOR, PURPLE_COLOR } from "theme/colors"
import { PX28 } from "theme/sizes"
import Button from "ui/Button"
import Container from "ui/Container"
import Link from "ui/Link"
import { SoonBadge } from "ui/SoonBadge"
import LessonImageAsset from "app/kids/assets/img_lessenenliedjes.png"
import StudioImageAsset from "app/kids/assets/img_studio.png"
import Image from "next/image"
import { BlitzPage } from "@blitzjs/auth"

const Grid = styled.div`
  display: grid;
  @media (min-width: 1024px) {
    grid-template-columns: 3fr 2fr;
  }
  gap: 26px;
`

const Card = styled.div`
  border-radius: 16px;
  background-color: #9956d3;
  position: relative;
  /* padding: 40px; */
`

const RoundedButton = styled(Button)`
  position: absolute;
  left: 40px;
  top: 40px;
  background: #0000004d;
  border: 3px solid #ffffff;
  height: 67px;
  border-radius: 36px;
  padding: 0 42px;
  color: white;
  font-size: ${PX28};
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
`

const KidsPage: BlitzPage = () => {
  const getLink = useLinks()
  return (
    <KidsLayout type="home">
      <Container>
        <Grid>
          <AspectRatio asChild ratio={800 / 494}>
            <Card
              style={{ backgroundColor: PURPLE_COLOR }}
              as={Link}
              {...getLink(Routes.KidsLessonPage())}
            >
              <Image src={LessonImageAsset} fill alt="" sizes="66vw" />
              <RoundedButton>
                <Value name="apps.web.pages.kids.preview.index.lessen_en_liedjes">Lessen en liedjes </Value><FaChevronRight size={16} />
              </RoundedButton>
            </Card>
          </AspectRatio>
          <AspectRatio asChild ratio={524 / 494}>
            <Card
              style={{ backgroundColor: GREEN_COLOR }}
              as={Link}
              {...getLink(Routes.KidsStudioPage())}
            >
              <Image src={StudioImageAsset} fill alt="" sizes="33vw" />
              <RoundedButton>
                <Value name="apps.web.pages.kids.preview.index.studio">Studio </Value><FaChevronRight size={16} />
              </RoundedButton>
            </Card>
          </AspectRatio>
          {/* <Card>
            <RoundedButton>Tip van 123ZING</RoundedButton>
          </Card> */}
        </Grid>
      </Container>
    </KidsLayout>
  )
}

KidsPage.authenticate = {
  redirectTo: "/login",
}

export default KidsPage
